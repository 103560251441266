import axios from "axios";
import { accountBaseUrl, config } from "./constGlobals";

export const logout_service = async (): Promise<IResponse> => {
  const url = `${accountBaseUrl}/Account/Logout`;

  try {
    const response = await axios.put(url, {}, config);
    return {
      isSuccessful: true,
      status: response.status,
      message: "Sesión cerrada exitosamente",
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccessful: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al cerrar sesión",
      data: null,
    };
  }
};
