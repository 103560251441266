import { IAccountInfo } from "@Models/interfaces/account";
import { HiMiniStar } from "react-icons/hi2";
// @ts-ignore
import style from "../../ModalAccessDenied.module.css";
//@ts-ignore
import { ButtonWithoutIcon, IconWrapper } from "@viuti/recursos";

interface IPlanDeniedProps {
  accountInfo: IAccountInfo;
}

const PlanDenied = ({ accountInfo }: IPlanDeniedProps) => {
  return (
    <div className={style.modal__access__denied}>
      <h2 className={style.modal__access__denied__title}>
        Lo sentimos, no tienes acceso a esta función.
      </h2>
      <div className={style.modal__access__denied__icon__container}>
        <span className={style.modal__access__denied__icon}>
          <IconWrapper icon={HiMiniStar} size={48} color="#937cf4" />
        </span>
      </div>
      <p className={style.modal__access__denied__description}>
        Esta característica no está disponible en el plan actual{" "}
        <b>{accountInfo.plan.name}</b> de tu empresa. Como administrador, puedes
        cambiar tu plan en la configuración de tu cuenta.
      </p>

      <ButtonWithoutIcon
        isPrimary
        textBttn="Mejorar mi plan"
        handleClick={() => window.location.replace("/mi-cuenta")}
      />
    </div>
  );
};

export default PlanDenied;
