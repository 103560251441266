import {
  HiBuildingStorefront,
  HiCurrencyDollar,
  HiInboxArrowDown,
  HiKey,
  HiMiniDocumentText,
  HiMiniUserCircle,
  HiUsers,
} from "react-icons/hi2";
import { FaBarcode, FaPeopleCarryBox } from "react-icons/fa6";
import {
  ACCOUNT_ROUTE,
  BILLING_ROUTE,
  COMMISSIONS_ROUTE,
  LOUNGES_ROUTE,
  MARKETING_ROUTE,
  PRODUCTS_ROUTE,
  REPORTS_ROUTE,
  SECURITY_ROUTE,
  SERVICES_ROUTE,
  SUPPLIERS_ROUTE,
  TEAM_ROUTE,
} from "../../models/localPath";
// @ts-ignore
import { Icon, IconWrapper } from "@viuti/recursos";
import { MarketingIcon, ServiceConfigIcon } from "@Models/icons";
import { v4 as uuid } from "uuid";

export const COMMON_NAVIGATION_OPTIONS = [
  {
    id: uuid(),
    label: "Gestionar mi negocio",
    items: [
      {
        id: uuid(),
        icon: <IconWrapper icon={HiUsers} size={16} />,
        name: "Mi equipo",
        redirectTo: TEAM_ROUTE,
        accessId: "5b6662ff-b0fd-4159-bea7-57d539b1e80b",
      },
      {
        id: uuid(),
        icon: <Icon size={16} color={"#392B75"} path={ServiceConfigIcon} />,
        name: "Servicios",
        redirectTo: SERVICES_ROUTE,
        accessId: "c1aa09fd-2794-4204-a32d-f5ec528ce9ec",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={FaBarcode} size={16} />,
        name: "Productos",
        redirectTo: PRODUCTS_ROUTE,
        accessId: "35916df5-8449-4319-949b-54e70b374228",
      },
      {
        id: uuid(),
        icon: <Icon size={16} color={"#392B75"} path={MarketingIcon} />,
        name: "Marketing",
        redirectTo: MARKETING_ROUTE,
        accessId: "e735103e-87b3-4dca-9e86-a9b2e32c651b",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiCurrencyDollar} size={16} />,
        name: "Comisiones",
        redirectTo: COMMISSIONS_ROUTE,
        accessId: "fc747aa5-5170-4fee-bbac-da9845fd43db",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={FaPeopleCarryBox} size={16} />,
        name: "Proveedores",
        redirectTo: SUPPLIERS_ROUTE,
        accessId: "",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiBuildingStorefront} size={16} />,
        name: "Locales",
        redirectTo: LOUNGES_ROUTE,
        accessId: "6b334362-feae-4552-bbee-b8c339ee0541",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiInboxArrowDown} size={16} />,
        name: "Reportes",
        redirectTo: REPORTS_ROUTE,
        accessId: "136937c6-857d-4d90-b011-983cfe5100ad",
      },
    ],
  },
  {
    id: uuid(),
    label: "Configuración",
    items: [
      {
        id: uuid(),
        icon: <IconWrapper icon={HiMiniUserCircle} size={16} />,
        name: "Mi cuenta",
        redirectTo: ACCOUNT_ROUTE,
        accessId: "b9d99deb-d24a-451b-a3bb-17cc4a09dbda",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiMiniDocumentText} size={16} />,
        name: "Facturación electrónica",
        redirectTo: BILLING_ROUTE,
        accessId: "16a03415-5599-4e93-a8e6-4f1abd14dbbd",
      },
    ],
  },
];

export const ADMIN_NAVIGATION_OPTIONS = [
  {
    id: uuid(),
    label: "Gestionar mi negocio",
    items: [
      {
        id: uuid(),
        icon: <IconWrapper icon={HiUsers} size={16} />,
        name: "Mi equipo",
        redirectTo: TEAM_ROUTE,
        accessId: "5b6662ff-b0fd-4159-bea7-57d539b1e80b",
      },
      {
        id: uuid(),
        icon: <Icon size={16} color={"#392B75"} path={ServiceConfigIcon} />,
        name: "Servicios",
        redirectTo: SERVICES_ROUTE,
        accessId: "c1aa09fd-2794-4204-a32d-f5ec528ce9ec",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={FaBarcode} size={16} />,
        name: "Productos",
        redirectTo: PRODUCTS_ROUTE,
        accessId: "35916df5-8449-4319-949b-54e70b374228",
      },
      {
        id: uuid(),
        icon: <Icon size={16} color={"#392B75"} path={MarketingIcon} />,
        name: "Marketing",
        redirectTo: MARKETING_ROUTE,
        accessId: "e735103e-87b3-4dca-9e86-a9b2e32c651b",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiCurrencyDollar} size={16} />,
        name: "Comisiones",
        redirectTo: COMMISSIONS_ROUTE,
        accessId: "fc747aa5-5170-4fee-bbac-da9845fd43db",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiBuildingStorefront} size={16} />,
        name: "Locales",
        redirectTo: LOUNGES_ROUTE,
        accessId: "6b334362-feae-4552-bbee-b8c339ee0541",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiInboxArrowDown} size={16} />,
        name: "Reportes",
        redirectTo: REPORTS_ROUTE,
        accessId: "136937c6-857d-4d90-b011-983cfe5100ad",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={FaPeopleCarryBox} size={16} />,
        name: "Proveedores",
        redirectTo: SUPPLIERS_ROUTE,
        accessId: "",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiKey} size={16} />,
        name: "Permisos",
        redirectTo: SECURITY_ROUTE,
        accessId: "",
      },
    ],
  },
  {
    id: uuid(),
    label: "Configuración",
    items: [
      {
        id: uuid(),
        icon: <IconWrapper icon={HiMiniUserCircle} size={16} />,
        name: "Mi cuenta",
        redirectTo: ACCOUNT_ROUTE,
        accessId: "b9d99deb-d24a-451b-a3bb-17cc4a09dbda",
      },
      {
        id: uuid(),
        icon: <IconWrapper icon={HiMiniDocumentText} size={16} />,
        name: "Facturación electrónica",
        redirectTo: BILLING_ROUTE,
        accessId: "16a03415-5599-4e93-a8e6-4f1abd14dbbd",
      },
    ],
  },
];
